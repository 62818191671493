import React from 'react';
import style from './FooterSocial.module.css';
import { TEXTS } from '../../texts';



const FooterSocial = () => {

    return (
        <div className={style.wrapper}>
            <div className={style.container}>

                <h3 className={style.title}>{TEXTS.footerSocialTitle}</h3>

                <ul className={style.list}>
                    <li className={style.item}>
                        <a
                            className={style.item__hover}
                            onClick={() => setTimeout(() => {
                                let open = window.open('https://www.linkedin.com/company/nitrix.io/')
                                open()
                            }, 500)}>
                            {TEXTS.LINKEDIN}
                        </a>
                    </li>
                    <li className={style.item}>
                        <a
                            className={style.item__hover}
                            onClick={() => setTimeout(() => {
                                let open = window.open('https://www.instagram.com/nitrix.soft/')
                                open()
                            }, 500)}>
                            {TEXTS.INSTAGRAM}
                        </a>
                    </li>
                    <li className={style.item}>
                        <a
                            className={style.item__hover}
                            onClick={() => setTimeout(() => {
                                let open = window.open('mailto:hello@nitrix.io')
                                open()
                            }, 500)}>
                            E-mail
                        </a>
                    </li>
                    <li className={style.item}>
                        <a
                            className={style.item__hover}
                            onClick={() => setTimeout(() => {
                                let open = window.open('https://www.upwork.com/ag/nitrix/')
                                open()
                            }, 500)}>
                            {TEXTS.UPWORK}
                        </a>
                    </li>
                </ul>

                <a href='#arrow-up' className={style.scrollUp}>Up</a>

            </div>
        </div>
    )
}

export default FooterSocial;